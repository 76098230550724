import Dexie from "dexie"

export interface IProject {
  id?: number // Primary key. Optional (autoincremented)
  cmiId: number
  name: number
  role: string
}

export interface ISite {
  id?: number // Primary key. Optional (autoincremented)
  cmiId: number
  projectId: number
  name: number
  canSeeNames: boolean
  canAddChildren: boolean
  inTrainingMode: boolean
  country: string
  growthEnabled: boolean
  anemiaEnabled: boolean
  earlyidEnabled: boolean
  isFeedingScreeningEnabled: boolean
  isChildMealtimeBestPracticeAssessmentEnabled: boolean
  areBehaviorAndEmotionRequired: boolean
  telCountryCode?: string
}

export interface IChild {
  id?: number // Primary key. Optional (autoincremented)
  cmiId?: number,
  hasDiagnosis: boolean,
  createdDuringTraining: boolean,
  diagnoses: [string],
  dob: Date,
  dobEstimated: boolean,
  firstName?: string,
  lastName?: string,
  sex: string,
  isAgedOutOfEarlyidAssessment: boolean,
  nextAnemiaAssessmentDate?: Date,
  nextEarlyidAssessmentDate?: Date,
  nextGrowthAssessmentDate?: Date,
  nextMealtimeAssessmentDate?: Date,
  nextBestPracticesAssessmentDate?: Date,
  isAgedOutOfBestPracticesAssessment?: boolean,
  nextMealtimeAssessmentType?: string,
  siteId: number,
  oldSiteId?: number
  typeOfCare: string,
  typeOfService: string,
  caregiverPhoneNumber?: string,
  contactInformation?: string,
  reasonForDischarge?: string,
  otherReasonForDischarge?: string,
  causeOfDeath?: string,
  otherCauseOfDeath?: string,
  dateOfAdmission?: Date,
  dateOfDeath?: Date,
  dateOfDischarge?: Date,
  siteChildId?: string,
  firstAssessmentDate?: Date,
}

export interface IGrowthAssessment {
  id?: number // Primary key. Optional (autoincremented)
  isComplete: boolean
  childId: number
  siteId: number
  dateCreated?: Date
  canChildStand: boolean
  canStraightenLegs: boolean
  cmiId?: number
  creatorName?: string
  dueDate?: Date
  dateOfAssessment: Date
  hasReferrals: boolean
  headCircumferenceInCm?: number
  weightIsDirty?: boolean
  lengthInCm?: number
  lengthIsDirty?: boolean
  muacInCm?: number
  typeOfLength?: string
  weightInKilograms?: number
  notes?: object
  headSizeIsDirty?: boolean
}

export interface IAnemiaAssessment {
  id?: number // Primary key. Optional (autoincremented)
  isComplete: boolean
  childId: number
  siteId: number
  cmiId?: number
  dateCreated?: Date
  dateOfAssessment: Date
  creatorName?: string
  dueDate?: Date
  notes?: Date
  hasInfection?: boolean
  hasReferrals: boolean
  hemoglobinLevel?: number
  testingLocation?: string
  testDate?: Date
  currentlyTakingIronSupplements?: boolean
  ironSupplementsTakenWithVitaminC?: boolean
  ironSupplementsMilligramsPerDay?: number
  ironSupplementFrequency?: string
  ironSupplementStartDate?: Date
}

export interface IDevelopmentalScreening {
  isComplete: boolean
  id?: number // Primary key. Optional (autoincremented)
  childId: number
  siteId: number
  modelName: string
  dateCreated?: Date
  cmiId?: number
  creatorName?: string
  dueDate?: Date
  dateOfAssessment: Date
  hasReferrals: boolean
  notes?: object
}

export interface IFeedingScreening {
  isComplete: boolean
  id?: number
  cmiId?: number
  childId: number
  siteId: number
  dateOfAssessment: Date
  dueDate?: Date
  notes?: object
  creatorName?: string
  doesChildCommunicateHunger: boolean
  doesChildEatEnough: boolean
  mealtimeDuration: string
  doesChildShowSignsOfAspiration: boolean
  doesChildEatReluctantly: boolean
  isChildNotGainingWeight: boolean
  areMealtimesStressful: boolean
  doesCaregiverHaveMealtimeConcerns: boolean
  hasReferrals: boolean
}

export interface IBestPracticeAssessment {
  isComplete: boolean
  id?: number
  cmiId?: number
  childId: number
  siteId: number
  dateOfAssessment: Date
  dueDate?: Date
  notes?: object
  creatorName?: string
  tools: Array<string>
  textures: Array<string>
  feeder: string
  bf?: string
  rf1?: boolean
  rf2?: boolean
  rf3?: boolean
  rf4?: boolean
  rf5?: boolean
  rf6?: boolean
  rf7?: boolean
  rf8?: string
  fp1?: boolean
  fp2?: string
  fp3?: boolean
  fp4?: boolean
  fp5?: boolean
  mf?: number
  mff?: number
  fg1a?: string
  fg2a?: string
  fg1b?: string
  fg2b?: string
  fg2c?: string
  fg3a?: string
  fg3b?: string
  fg4?: string
  fg5?: string
  fg6?: string
  fg7?: string
  fg8?: string

  hasReferrals: boolean
}

export interface ILastUpdated {
  id?: number // Primary key. Optional (autoincremented)
  type: string
  localItemId?: number
  date: Date
}

export interface IPendingUpload {
  id?: number // Primary key. Optional (autoincremented)
  type: string
  localItemId: number
}

export class CountMeInDatabase extends Dexie {
  projects!: Dexie.Table<IProject, number>
  sites!: Dexie.Table<ISite, number>
  children!: Dexie.Table<IChild, number>
  anemiaAssessments!: Dexie.Table<IAnemiaAssessment, number>
  growthAssessments!: Dexie.Table<IGrowthAssessment, number>
  developmentalScreenings!: Dexie.Table<IDevelopmentalScreening, number>
  feedingScreenings!: Dexie.Table<IFeedingScreening, number>
  childBestPracticeAssessments!: Dexie.Table<IBestPracticeAssessment, number>
  lastUpdated!: Dexie.Table<ILastUpdated, number>
  pendingUploads!: Dexie.Table<IPendingUpload, number>

  constructor(name: string) {
    super(name)

    this.version(1).stores({
      projects: "++id, cmiId, name",
      sites: "++id, cmiId, projectId, name",
      children: "++id, cmiId, siteId, dob",
      anemiaAssessments: "++id, childId, siteId, dateCreated",
      growthAssessments: "++id, childId, siteId, dateCreated",
      developmentalScreenings: "++id, childId, siteId, dateCreated",
      feedingScreenings: "++id, childId, siteId, dateCreated",
      childBestPracticeAssessments: "++id, childId, siteId, dateCreated",
      lastUpdated: "++id, type, [type+localItemId]",
      // Pending Uploads table is just a *local* id and a type (child, growthAssessment, etc).
      // Entries are not prioritized; separate logic handles order of upload.
      pendingUploads: "++id, type, [type+localItemId]",
    })
  }

}

export const db = new CountMeInDatabase("countMeIn")
