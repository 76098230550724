import { getProjectRolesOptions, getSiteRolesOptions } from "@/utils/Options"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

export function getLoginSchema() {
  return [
    {
      $formkit: "text",
      id: "username",
      name: "username",
      label: $gettext("Username"),
      value: "$username",
      validation: "required",
    },
    {
      $formkit: "password",
      name: "password",
      label: $gettext("Password"),
      validation: "required",
    },
  ]
}

export function getPasswordSchema() {
  return [
    {
      $formkit: "password",
      name: "new_password",
      id: "new_password",
      label: $gettext("New password"),
      validation: "required",
    },
    {
      $formkit: "password",
      name: "new_password_confirm",
      id: "new_password_confirm",
      label: $gettext("Confirm new password"),
      validation: "required|confirm",
      "validation-messages": { confirm: $gettext("New passwords do not match.") },
    },
  ]
}

export function getOwnPasswordSchema() {
  return [
    {
      $formkit: "password",
      name: "old_password",
      id: "old_password",
      label: $gettext("Old password"),
      validation: "required",
    },
    ...getPasswordSchema()
  ]
}

export function getAccountInfoSchema() {
  return [
    {
      $formkit: "text",
      id: "firstName",
      name: "firstName",
      value: "$firstName",
      label: $gettext("First Name"),
      validation: "required",
    },
    {
      $formkit: "text",
      id: "lastName",
      name: "lastName",
      value: "$lastName",
      label: $gettext("Last Name"),
      validation: "required",
    },
    {
      $formkit: "email",
      id: "email",
      name: "email",
      value: "$email",
      label: $gettext("Email"),
      validation: "required",
    },
  ]
}

function getAccountSchema() {
  return [
    ...getAccountInfoSchema(),
    {
      $formkit: "password",
      id: "password",
      name: "password",
      label: $gettext("Password"),
    },
  ]
}

function getSiteRoleSchema() {
  return [
    {
      $formkit: "select",
      id: "role",
      name: "role",
      label: $gettext("Role"),
      options: getSiteRolesOptions(),
      validation: "required",
      "inner-class": "select",
    },
  ]
}

function getProjectRoleSchema() {
  return [
    {
      $formkit: "select",
      id: "role",
      name: "role",
      label: $gettext("Role"),
      options: getProjectRolesOptions(),
      validation: "required",
      "inner-class": "select",
    },
  ]
}

export function getAccountAndSiteRoleSchema() {
  return [...getAccountSchema(), ...getSiteRoleSchema()]
}

export function getAccountAndProjectRoleSchema() {
  return [...getAccountSchema(), ...getProjectRoleSchema()]
}
